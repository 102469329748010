import { SharedComponents, Theme } from "@shared";

import { QualityTypeItem, P } from "./lib";
import { OPTIONS } from "./constants";
import { useCallback } from "react";
import { useField } from "formik";

interface IQualityOptions {
  field: string;
}

export const QualityTypes = ({ field }: IQualityOptions) => {
  const {
    spacing: { lg },
    palette: { primaryRed },
  } = Theme.useStyledTheme();

  const [input] = useField<boolean>("hasPii");
  const [, meta] = useField<boolean>(field);

  const renderItem = useCallback(
      ({ src, name, description, spec, value }) => (
          <QualityTypeItem
              value={value}
              field={field}
              src={src}
              name={name}
              description={description}
              spec={spec}
              key={name}
          />
    ),
    [field]
  );

  if (!input.value) {
    return <></>;
  }

  return (
    <SharedComponents.Column>
      <SharedComponents.Text type={"h3SemiBold"} text={"Quality algorithms:"} />
      <P>
        Choose the option applying the <u>highest level of quality</u> that enables
        your project videos to be usable for the research purpose.
      </P>
      {meta.touched && meta.error && (
        <SharedComponents.Text
          color={primaryRed}
          type={"xSmall"}
          text={meta.error}
        />
      )}
      <SharedComponents.Row>{OPTIONS.map(renderItem)}</SharedComponents.Row>
      <SharedComponents.VerticalBox height={lg} />
    </SharedComponents.Column>
  );
};
