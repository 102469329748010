import gql from "graphql-tag";
import {Auth} from "aws-amplify";
import {makeObservable} from "mobx";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import AWSAppSyncClient, {AUTH_TYPE} from "aws-appsync";
import localforage from "localforage";

import {FileUploader} from "../../../../shared/store/basic";

import {Types} from "@videoAnalytics";

import {
    projects,
    projectsStats,
    sharedProjects,
    sharedProjectsStats,
    projectDetails,
    userProjectsStats,
    userProjects,
    videoDetails,
    videosDetails,
    videoClips,
    userRole,
    costCenterExist,
    projectExist,
    searchUsers,
    videoTranscript,
    searchProjects,
    searchVideoTranscript,
    elasticSearch,
    projectMedia,
} from "./queries";

import {
    updateVideo,
    updateClipsVideo,
    createVideo,
    createFile,
    deleteFile,
    deleteVideo,
    createClipsVideo,
    createProject,
    deleteProject,
    updateProject,
} from "./mutations";

export class Client extends FileUploader {
    client: AWSAppSyncClient<any>;

    constructor(awsClient: typeof AWSAppSyncClient) {
        super();
        this.client = new awsClient({
            url: process.env.REACT_APP_APPSYNC_GRAPHQL_ENDPOINT || "",
            region: process.env.REACT_APP_COGNITO_REGION || "",
            auth: {
                type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
                jwtToken: async () =>
                    (await Auth.currentSession()).getAccessToken().getJwtToken(),
            },
            offlineConfig: {
                keyPrefix: "video-analytics",
                storage: localforage,
            },
        });
        makeObservable(this, {client: true});
    }

    searchProjects = async (input: Types.ISearchProjectsInput) => {
        const response = (await this.client.query({
            query: gql(searchProjects),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.ISearchProjectsOutput>;
        return response.data;
    };

    fullSearch = async (input: Types.IFullSearchInput) => {
        const response = (await this.client.query({
            query: gql(elasticSearch),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IFullSearchOutput>;
        return response.data;
    };

    projectMedia = async (input: Types.IProjectMediaInput) => {
        const response = (await this.client.query({
            query: gql(projectMedia),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IProjectMediaOutput>;
        return response.data;
    };

    searchVideoTranscript = async (input: Types.ISearchVideoTranscriptInput) => {
        const response = (await this.client.query({
            query: gql(searchVideoTranscript),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.ISearchVideoTranscriptOutput>;
        return response.data;
    };

    projectNameExist = async (name: string) => {
        const response = (await this.client.query({
            query: gql(projectExist),
            variables: {input: {name}},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IProjectExistOutput>;
        return response.data?.projectExist;
    };

    projects = async (input: Types.IProjectsInput) => {
        const response = (await this.client.query({
            query: gql(projects),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IProjectsOutput>;
        return response.data;
    };

    sharedProjects = async (input: Types.ISharedProjectsInput) => {
        const response = (await this.client.query({
            query: gql(sharedProjects),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.ISharedProjectsOutput>;
        return response.data;
    }

    updateVideo = async (input: Types.IUpdateVideoInput) => {
        const response = (await this.client.mutate({
            mutation: gql(updateVideo),
            variables: {input},
        })) as GraphQLResult<Types.IUpdateVideoOutput>;
        return response.data;
    };

    createClipsVideo = async (input: Types.ICreateClipsInput) => {
        await this.client.mutate({
            mutation: gql(createClipsVideo),
            variables: {input},
        });
    };

    projectsStats = async () => {
        const response = (await this.client.query({
            query: gql(projectsStats),
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IProjectsStatsOutput>;
        return response.data;
    };

    sharedProjectsStats = async () => {
        const response = (await this.client.query({
            query: gql(sharedProjectsStats),
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.ISharedProjectsStatsOutput>;
        return response.data;
    }

    videoTranscript = async (input: Types.IVideoTranscriptsInput) => {
        const response = (await this.client.query({
            query: gql(videoTranscript),
            fetchPolicy: "network-only",
            variables: {input},
        })) as GraphQLResult<Types.IVideoTranscriptsOutput>;
        return response.data;
    };

    projectDetails = async (input: Types.IProjectDetailsInput) => {
        const response = (await this.client.query({
            query: gql(projectDetails),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IProjectDetailsOutput>;
        return response.data;
    };

    userProjectsStats = async () => {
        const response = (await this.client.query({
            query: gql(userProjectsStats),
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IUserProjectsStatsOutput>;
        return response.data;
    };

    userProjects = async (input: Types.IUserProjectsInput) => {
        const response = (await this.client.query({
            query: gql(userProjects),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IUserProjectsOutput>;
        return response.data;
    };

    videoDetails = async (input: Types.IVideoDetailsInput) => {
        const response = (await this.client.query({
            query: gql(videoDetails),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IVideoDetailsOutput>;
        return response.data;
    };

    videosDetails = async (input: Types.IVideosDetailsInput) => {
        const response = (await this.client.query({
            query: gql(videosDetails),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IVideosDetailsOutput>;
        return response.data;
    };

    videoClips = async (input: Types.IVideoClipsInput) => {
        const response = (await this.client.query({
            query: gql(videoClips),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IVideoClipsOutput>;
        return response.data;
    };

    updateClipsVideo = async (input: Types.IUpdateClipsVideoInput) => {
        const response = (await this.client.mutate({
            mutation: gql(updateClipsVideo),
            variables: {input},
        })) as GraphQLResult<Types.IUpdateClipsVideoOutput>;
        return response;
    };

    userRole = async (input: Types.IUserRoleInput) => {
        const response = (await this.client.query({
            query: gql(userRole),
            variables: {input},
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.IUserRoleOutput>;
        return response.data;
    };

    createVideo = async (input: Types.ICreateVideoInput) => {
        const response = (await this.client.mutate({
            mutation: gql(createVideo),
            variables: {input},
        })) as GraphQLResult<Types.ICreateVideoOutput>;
        return response.data;
    };

    createFile = async (input: Types.ICreateFileInput) => {
        const response = (await this.client.mutate({
            mutation: gql(createFile),
            variables: {input},
        })) as GraphQLResult<Types.ICreateFileOutput>;
        return response.data;
    };

    deleteVideo = async (input: Types.IDeleteVideoInput) => {
        const response = (await this.client.mutate({
            mutation: gql(deleteVideo),
            variables: {input},
        })) as GraphQLResult<Types.IDeleteVideoOutput>;
        return response;
    };

    deleteFile = async (input: Types.IDeleteFileInput) => {
        const response = (await this.client.mutate({
            mutation: gql(deleteFile),
            variables: {input},
        })) as GraphQLResult<Types.IDeleteFileOutput>;
        return response;
    };

    searchUsers = async (input: Types.ISearchUsersInput) => {
        const response = (await this.client.query({
            query: gql(searchUsers),
            variables: input,
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.ISearchUsersOutput>;
        return response.data;
    };

    costCenterExist = async (input: Types.ICostCenterExistInput) => {
        const response = (await this.client.query({
            query: gql(costCenterExist),
            variables: input,
            fetchPolicy: "network-only",
        })) as GraphQLResult<Types.ICostCenterExistOutput>;
        return response.data?.costCenterExist;
    };

    createProject = async (input: Types.ICreateProjectInput) => {
        const response = (await this.client.mutate({
            mutation: gql(createProject),
            variables: {input},
        })) as GraphQLResult<Types.ICreateProjectOutput>;
        return response.data;
    };

    deleteProject = async (input: Types.IDeleteProjectInput) => {
        await this.client.mutate({
            mutation: gql(deleteProject),
            variables: {input},
        });
    };

    updateProject = async (input: Types.IUpdateProjectInput) => {
        await this.client.mutate({
            mutation: gql(updateProject),
            variables: {input},
        });
    };
}
