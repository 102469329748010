import {makeObservable, observable} from "mobx";

import {Types} from "@videoAnalytics";

import * as SharedTypes from "../../../../shared/types";
import {BasicData} from "../../../../shared/store/basic";

export class Data extends BasicData {
    userEmails: Array<string>;
    hasMoreProjects: boolean = true;
    projects: Array<Types.IProject>;
    elasticProjects: Array<Types.ISearchItem>;
    elasticVideos: Array<Types.ISearchItem>;
    projectsStats?: Types.IProjectsStats = undefined;
    myProjectsStats?: Types.IProjectsStats = undefined;
    sharedProjectsStats?: Types.IProjectsStats = undefined;
    currentProjectsPage: number = 1;
    selectedProject?: Types.IProjectDetails = undefined;
    selectedProjectRole: string = "NONE";
    selectedFileRole: string = "NONE";
    videosDetails?: Array<Types.IVideoDetails> = undefined;
    videoDetails?: Types.IVideoDetails = undefined;
    videoClips?: Types.IVideoClips = undefined;
    videoTranscript: Array<Types.ITranscript> = [];
    videoTranscriptSentencesNumber: number = 0;
    hasMoreVideoTranscript: boolean = true;
    videoTranscriptListControlPoints: Map<number, number> = observable.map();
    videoTranscriptPlayerProgress: number = 0;
    videoTranscriptPage: number = 1;
    videoTranscriptSearchValue: string = "";
    searchedVideoTranscripts: Array<Types.ISearchVideoTranscriptItem> = [];
    projectFiles: Array<Types.IFile> = [];
    projectSummaries: Array<Types.IVideo> = [];
    projectVideos: Array<Types.IVideo> = [];
    totalCount: number = 0;
    pageCount: number = 0;

    constructor() {
        super();
        this.elasticProjects = [];
        this.elasticVideos = [];
        this.projects = [];
        this.userEmails = [];
        makeObservable(this, {
            projectSummaries: true,
            projectVideos: true,
            projectFiles: true,
            pageCount: true,
            totalCount: true,
            userEmails: true,
            hasMoreProjects: true,
            projects: true,
            currentProjectsPage: true,
            projectsStats: true,
            myProjectsStats: true,
            selectedProject: true,
            selectedProjectRole: true,
            selectedFileRole: true,
            videosDetails: true,
            videoDetails: true,
            videoClips: true,
            elasticProjects: true,
            elasticVideos: true,
            videoTranscript: true,
            videoTranscriptSentencesNumber: true,
            searchedVideoTranscripts: true,
            hasMoreVideoTranscript: true,
            videoTranscriptListControlPoints: true,
            videoTranscriptPlayerProgress: true,
            videoTranscriptSearchValue: true,
            videoTranscriptPage: true,
        });
    }

    setProjectMedia = (projectMedia?: Types.IProjectMedia) => {
        if (!projectMedia) return;
        this.projectSummaries = projectMedia.summaries;
        this.projectVideos = projectMedia.videos;
        this.projectFiles = projectMedia.files;
        this.pageCount = projectMedia.pageCount;
        this.totalCount = projectMedia.totalCount;
    };

    setVideoTranscriptPage = (p: number) => {
        this.videoTranscriptPage = p;
    };

    setVideoTranscriptPlayerProgress = (p: number) => {
        this.videoTranscriptPlayerProgress = p;
    };

    setVideoTranscriptListControlPoints = (k: number, v: number) => {
        if (this.videoTranscriptListControlPoints.get(k) === v) return;
        this.videoTranscriptListControlPoints.set(k, v);
    };

    clearVideoTranscriptListControlPoints = () => {
        this.videoTranscriptListControlPoints.clear();
        this.videoTranscriptPage = 1;
    };

    setVideoTranscriptSearchValue = (value: string) => {
        this.videoTranscriptSearchValue = value;
    };

    setSearchedVideoTranscript = (
        searchedVideoTranscripts: Array<Types.ISearchVideoTranscriptItem>
    ) => {
        this.searchedVideoTranscripts = searchedVideoTranscripts;
    };

    clearVideoTranscript = () => {
        this.videoTranscript = [];
        this.hasMoreVideoTranscript = true;
    };

    setVideoTranscript = (response: Types.IVideoTranscriptsOutput) => {
        this.videoTranscriptSentencesNumber =
            response.videoTranscript.sentencesNumber;
        const sentences = response.videoTranscript.sentences;
        if (
            this.videoTranscriptPage === 1 &&
            this.videoTranscript.length > 0 &&
            sentences.length > 0
        ) {
            return;
        }
        if (sentences.length === 0) {
            this.hasMoreVideoTranscript = false;
            return;
        }
        const newTranscript = response.videoTranscript.sentences.map(
            ({endTime, startTime, language, text, translatedText, speakerId}) => ({
                endTime,
                startTime,
                language,
                text,
                translatedText,
                speakerId,
            })
        );
        this.videoTranscript.push(...newTranscript);
    };

    setVideoDetails = (videoDetails: Types.IVideoDetails) => {
        this.videoDetails = videoDetails;
    };

    setVideosDetails = (
        videosDetails: Array<Types.IVideoDetails> | undefined
    ) => {
        this.videosDetails = videosDetails;
    };

    setFullSearchData = (data?: Types.IFullSearchOutput) => {
        if (!data) return;
        this.elasticProjects = data.fullSearch.projects;
        this.elasticVideos = data.fullSearch.videos;
    };

    clearFullSearchData = () => {
        this.elasticVideos = [];
        this.elasticProjects = [];
    };

    setSelectedFileRole = (role: string) => {
        this.selectedFileRole = role;
    };

    setSelectedProjectRole = (role: string) => {
        this.selectedProjectRole = role;
    };

    setSelectedProject = (project?: Types.IProjectDetails) => {
        if (!project) return;
        this.selectedProject = project;
    };

    clearSelectedProject = () => {
        this.selectedProject = undefined;
        this.selectedProjectRole = "NONE";
    };

    clearVideoDetails = () => {
        this.videoDetails = undefined;
    };

    setProjects = (projects: Array<Types.IProject>) => {
        this.projects = projects;
    };

    clearProjects = () => {
        this.projects = [];
    };

    setMoreProjects = (hasMore: boolean) => {
        this.hasMoreProjects = hasMore;
    };

    mergeProjects = (data: Array<Types.IProject>) => {
        if (data.length === 0) {
            this.setMoreProjects(false);
        }
        this.projects = [...this.projects, ...data];
    };

    setProjectStats = (projectsStats: Types.IProjectsStats) => {
        this.projectsStats = projectsStats;
    };

    setMyProjectsStats = (userProjectsStats: Types.IProjectsStats) => {
        this.myProjectsStats = userProjectsStats;
    };

    setSharedProjectsStats = (sharedProjectsStats: Types.IProjectsStats) => {
        this.sharedProjectsStats = sharedProjectsStats;
    };

    setCurrentProjectsPage = (page: number) => {
        this.currentProjectsPage = page;
    };

    setEmails = (response: Types.ISearchUsersOutput) => {
        this.userEmails = response.searchUsers.map((u) => u.email);
    };

    setVideoClips = (videoClips?: Types.IVideoClips) => {
        this.videoClips = videoClips;
    };

    get isProjectOwner() {
        return (
            this.selectedProjectRole === SharedTypes.UserRole.OWNER ||
            this.selectedProjectRole === SharedTypes.UserRole.ADMIN
        );
    }

    get isProjectMember() {
        return this.selectedProjectRole === SharedTypes.UserRole.USER;
    }

    get isFileOwner() {
        return (
            this.selectedFileRole === SharedTypes.UserRole.OWNER ||
            this.selectedFileRole === SharedTypes.UserRole.ADMIN
        );
    }

    get isFileAdmin() {
        return this.selectedFileRole === SharedTypes.UserRole.ADMIN;
    }

    get isFileMember() {
        return this.selectedFileRole === SharedTypes.UserRole.USER;
    }
}
