export const costCenterExist = /* GraphQL */ `
  query CostCenterExist($id: String) {
    costCenterExist(id: $id)
  }
`;

export const projectMedia = /* GraphQL */ `
  query ProjectMedia($input: ProjectMediaInput!) {
    projectMedia(input: $input) {
      pageCount
      totalCount
      files {
        active
        updatedAt
        thumbnail
        status
        slug
        rawPath
        projectName
        processedPath
        name
        ownerEmail
        metadata {
          parentId
        }
        id
        fileUrl
        createdAt
        disabledAt
      }
      summaries {
        active
        createdAt
        id
        name
        ownerEmail
        processedAt
        processedPath
        disabledAt
        projectName
        rawPath
        status
        summary
        thumbnail
        updatedAt
        videoLength
      }
      videos {
        active
        createdAt
        disabledAt
        id
        name
        ownerEmail
        processedAt
        processedPath
        projectName
        rawPath
        status
        summary
        thumbnail
        updatedAt
        videoLength
      }
    }
  }
`;

export const fullSearch = /* GraphQL */ `
  query FullSearch($input: FullSearchInput!) {
    fullSearch(input: $input) {
      projects {
        active
        description
        id
        name
        ownerEmail
        status
        tags {
          id
          name
        }
        taxonomy
        updatedAt
        videos {
          active
          videoLength
          updatedAt
          thumbnail
          summary
          status
          projectName
          processedAt
          ownerEmail
          name
          id
          createdAt
          disabledAt
        }
        videosNumber
      }
      stats {
        membersNumber
        projectsNumber
        videosNumber
        totalLength
      }
    }
  }
`;

export const elasticSearch = /* GraphQL */ `
  query FullSearch($input: FullSearchInput!) {
    fullSearch(input: $input) {
      projects {
        id
        name
        projectId
        results {
          key
          value {
            matchType
            value
          }
        }
      }
      videos {
        id
        name
        projectId
        results {
          key
          value {
            matchType
            value
          }
        }
      }
    }
  }
`;

export const searchVideoTranscript = /* GraphQL */ `
  query SearchVideoTranscript($input: VideoTranscriptSearchInput!) {
    searchVideoTranscript(input: $input) {
      results {
        startTime
        endTime
        text
        speakerId
      }
    }
  }
`;

export const projectDetails = /* GraphQL */ `
  query projectDetails($input: ProjectDetailsInput!) {
    projectDetails(input: $input) {
      FSDSUploadDate
      active
      brands {
        id
        name
        parentId
      }
      categories {
        id
        name
      }
      clp
      consentFormLink
      costCenter
      countries {
        id
        name
        parentId
      }
      description
      emotions {
        basic {
          negative {
            color
            count
            percent
          }
          neutral {
            color
            count
            percent
          }
          positive {
            color
            count
            percent
          }
        }
        detailed {
          angry {
            color
            count
            percent
          }
          calm {
            percent
            count
            color
          }
          confused {
            color
            count
            percent
          }
          disgusted {
            color
            count
            percent
          }
          fear {
            color
            count
            percent
          }
          happy {
            count
            percent
            color
          }
          sad {
            color
            count
            percent
          }
          surprised {
            color
            count
            percent
          }
        }
      }
      endDate
      hasConsentFormSignedByParent
      hasCostsAgreed
      hasDelayedFSDSUpload
      hasSignedInformedConsent
      hasPii
      hasVideoPersonsUnder18
      id
      members {
        email
        id
      }
      name
      ownerEmail
      regions {
        id
        name
      }
      startDate
      stats {
        filesNumber
        summariesLength
        videosLength
        totalLength
        summariesNumber
        videosNumber
      }
      status
      tags {
        id
        name
      }
      taxonomy
      updatedAt
    }
  }
`;

export const projectExist = /* GraphQL */ `
  query projectExist($name: String) {
    projectExist(name: $name)
  }
`;

export const projectFormMetaData = /* GraphQL */ `
  query MyQuery {
    projectFormMetadata {
      brands {
        id
        name
        parentId
      }
      categories {
        id
        name
      }
      countries {
        id
        name
        parentId
      }
      regions {
        id
        name
      }
    }
  }
`;

export const projects = /* GraphQL */ `
  query MyQuery($input: GetProjectsInput!) {
    projects(input: $input) {
      hasPii
      videos {
        videoLength
        active
        createdAt
        disabledAt
        id
        name
        ownerEmail
        processedAt
        projectName
        status
        thumbnail
        summary
        updatedAt
      }
      active
      description
      id
      name
      ownerEmail
      status
      tags {
        id
        name
      }
      taxonomy
      updatedAt
      videosNumber
    }
  }
`;

export const projectsStats = /* GraphQL */ `
  query MyQuery {
    projectsStats {
      membersNumber
      projectsNumber
      totalLength
      videosNumber
    }
  }
`;

export const sharedProjects = /* GraphQL */ `
  query MyQuery($input: GetProjectsInput!) {
    sharedProjects(input: $input) {
      hasPii
      videos {
        videoLength
        active
        createdAt
        disabledAt
        id
        name
        ownerEmail
        processedAt
        projectName
        status
        thumbnail
        summary
        updatedAt
      }
      active
      description
      id
      name
      ownerEmail
      status
      tags {
        id
        name
      }
      taxonomy
      updatedAt
      videosNumber
    }
  }
`;

export const sharedProjectsStats = /* GraphQL */ `
  query MyQuery {
    sharedProjectsStats {
      membersNumber
      projectsNumber
      totalLength
      videosNumber
    }
  }
`;

export const searchUsers = /* GraphQL */ `
  query SearchUsers($text: String!) {
    searchUsers(text: $text) {
      email
    }
  }
`;

export const userGlobalRole = /* GraphQL */ `
  query MyQuery($input: UserGlobalRoleInput!) {
    userGlobalRole(input: $input) {
      role
    }
  }
`;

export const userProjects = /* GraphQL */ `
  query MyQuery($input: GetProjectsInput!) {
    userProjects(input: $input) {
      videos {
        videoLength
        active
        createdAt
        disabledAt
        id
        name
        ownerEmail
        processedAt
        projectName
        status
        thumbnail
        summary
        updatedAt
      }
      active
      description
      id
      name
      ownerEmail
      status
      tags {
        id
        name
      }
      hasPii
      taxonomy
      updatedAt
      videosNumber
    }
  }
`;

export const userProjectsStats = /* GraphQL */ `
  query MyQuery {
    userProjectsStats {
      membersNumber
      projectsNumber
      totalLength
      videosNumber
    }
  }
`;

export const userRole = /* GraphQL */ `
  query userRole($input: UserEntityRoleInput!) {
    userRole(input: $input) {
      role
    }
  }
`;

export const videoClips = /* GraphQL */ `
  query videoClips($input: VideoClipsInput!) {
    videoClips(input: $input) {
      projectId
      summary
      name
      videos {
        id
        name
        videoUrl
        clips {
          endTime
          precedence
          startTime
          thumbnail
        }
      }
    }
  }
`;

export const searchProjects = /* GraphQL */ `
  query searchProjects($input: ProjectSearchInput!) {
    searchProjects(input: $input) {
      projects {
        active
        description
        id
        name
        ownerEmail
        status
        taxonomy
        updatedAt
        videos {
          active
          createdAt
          disabledAt
          id
          name
          ownerEmail
          projectName
          status
          summary
          thumbnail
          updatedAt
          videoLength
          processedAt
        }
        tags {
          id
          name
        }
        videosNumber
      }
      stats {
        membersNumber
        projectsNumber
        totalLength
        videosNumber
      }
    }
  }
`;

export const videoTranscript = /* GraphQL */ `
  query VideoTranscript($input: VideoTranscriptInput!) {
    videoTranscript(input: $input) {
      sentencesNumber
      sentences {
        endTime
        language
        sentiment {
          mixed {
            color
            percent
          }
          negative {
            color
            percent
          }
          neutral {
            color
            percent
          }
          positive {
            color
            percent
          }
        }
        speakerId
        startTime
        text
        translatedText
      }
    }
  }
`;

export const videoDetails = /* GraphQL */ `
  query VideoDetails($input: VideoDetailsInput!) {
    videoDetails(input: $input) {
      active
      createdAt
      disabledAt
      outputFiles {
        rawPath
        name
        processedPath
        projectName
        ownerEmail
        slug
        status
        thumbnail
        updatedAt
        active
        createdAt
        fileUrl
        id
        disabledAt
      }
      emotions {
        basic {
          endTime
          negative {
            color
            count
            percent
          }
          neutral {
            color
            count
            percent
          }
          positive {
            color
            count
            percent
          }
          startTime
        }
        detail {
          angry {
            color
            count
            percent
          }
          confused {
            color
            count
            percent
          }
          disgusted {
            color
            count
            percent
          }
          fear {
            color
            count
            percent
          }
          happy {
            color
            count
            percent
          }
          calm {
            color
            count
            percent
          }
          surprised {
            color
            count
            percent
          }
          endTime
          startTime
          sad {
            percent
            count
            color
          }
        }
      }
      name
      ownerEmail
      processedAt
      processedPath
      projectHasPii
      rawPath
      downloadPath
      projectName
      id
      stats {
        totalWordsNumber
        videoLength
      }
      status
      summary
      tags
      thumbnail
      transcripts {
        emotions {
          general {
            sentiment
            sentimentScore {
              mixed {
                color
                percent
              }
              negative {
                color
                percent
              }
              neutral {
                color
                percent
              }
              positive {
                color
                percent
              }
            }
          }
          tracked {
            parts {
              sentences {
                endTime
                sentiment {
                  mixed {
                    percent
                    color
                  }
                  negative {
                    color
                    percent
                  }
                  neutral {
                    color
                    percent
                  }
                  positive {
                    color
                    percent
                  }
                }
                language
                speakerId
                startTime
                text
                translatedText
              }
            }
          }
        }
        topWords {
          number
          word
        }
        translatedTopWords {
          number
          word
        }
      }
      updatedAt
    }
  }
`;

export const videoExist = /* GraphQL */ `
  query VideoNameExist($slug: String) {
    videoExist(slug: $slug)
  }
`;

export const videosDetails = /* GraphQL */ `
  query videosDetails($input: VideoDetailsInput!) {
    videosDetails(input: $input) {
      processedPath
      processedAt
      ownerEmail
      id
      active
      createdAt
      disabledAt
      projectName
      rawPath
      status
      summary
      tags
      thumbnail
      name
    }
  }
`;
