import { computed } from "mobx";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";

import {
  SharedStore,
  SharedComponents,
  Pages,
  Types as SharedTypes,
  Utils as SharedUtils,
} from "@shared";
import {
  VideoAnalyticsStore,
  VideoAnalyticsComponents,
  Types,
  Templates,
  Utils,
} from "@videoAnalytics";

import { initialValues } from "./formik_data";

import { PROJECT_EDITION_STEPS } from "./constants";
import * as LocalPages from "./pages";
import { IValues } from "./types";

export const EditProject = observer(() => {
  const { projectId } = useParams();
  const { getProjectMetadata, data, projectExist } = SharedStore;
  const {
    getProject,
    projectMedia,
    clearSelectedProject,
    client: { clearData },
    data: { loadings, setRequestReset, selectedProject, projectVideos },
  } = VideoAnalyticsStore;

  /**
   * FORMIK DATA
   * */
  const validationSchema = Utils.getValidationSchema({
    nameVerificationCb: projectExist,
    hasEditMode: true,
    name: selectedProject?.name,
  });

  const hasProjectDetailsLoading = SharedUtils.useQueryStatus(
    loadings,
    Types.Endpoints.PROJECT_DETAILS
  );
  const hasTaxonomyLoading = SharedUtils.useQueryStatus(
    data.loadings,
    Types.Endpoints.PROJECT_FORM_METADATA
  );

  const formValues: Types.ICreateProjectFormValues = computed(() => {
    if (!selectedProject) return initialValues;

    const {
      id,
      name,
      description,
      hasPii,
      hasSignedInformedConsent,
      hasConsentFormSignedByParent,
      hasCostsAgreed,
      hasDelayedFSDSUpload,
      hasVideoPersonsUnder18,
      status,
      brands,
      regions,
      countries,
      categories,
      clp,
      members,
      costCenter,
      endDate,
      startDate,
      tags,
    } = selectedProject;

    return {
      id,
      name,
      summary: description,
      brands: brands?.map((item) => item.id) || [],
      regions: regions?.map((item) => item.id) || [],
      countries: countries?.map((item) => item.id) || [],
      categories: categories?.map((item) => item.id) || [],
      hasPii,
      hasSignedInformedConsent,
      hasConsentFormSignedByParent,
      hasCostsAgreement: false,
      hasCostsAgreed,
      hasDelayedFSDSUpload,
      hasVideoPersonsUnder18,
      status,
      cmk: clp,
      email: "",
      members: members?.map((item) => item.email) || [],
      tags: tags?.map((tag) => tag.name) || [],
      files: [],
      videos: projectVideos?.map(
        ({
          id,
          name,
          summary,
          status,
          disabledAt,
          projectName,
          processedAt,
          updatedAt,
          ownerEmail,
          createdAt,
        }) => ({
          id,
          name,
          summary,
          status,
          disabledAt,
          projectName,
          processedAt,
          updatedAt,
          projectId: id,
          ownerEmail,
          createdAt,
        })
      ) || [],
      blurMode: "",
      qualityMode: "",
      customTag: "",
      costCenter,
      consentFormLink: "",
      startDate: moment(startDate).toDate(),
      endDate: moment(endDate).toDate(),
      FSDSUploadDate: new Date(),
    };
  }).get();

  const [isAssistanceModalOpen, setAssistanceModalOpen] = useState(false);

  useEffect(() => {
    const init = async () => {
      Promise.all([
        getProjectMetadata(),
        getProject(projectId || ""),
        projectMedia({
          id: projectId || "",
          fileType: "video",
          page: 1,
          itemsPerPage: 1000,
        }),
      ]);
    };
    setAssistanceModalOpen(true);
    init();
    return () => {
      clearData();
      clearSelectedProject();
      setRequestReset(Types.Endpoints.UPDATE_PROJECT);
      setRequestReset(Types.Endpoints.CREATE_PROJECT);
    };
  }, []);

  if (hasProjectDetailsLoading || hasTaxonomyLoading) {
    return <SharedComponents.LoadingIndicator />;
  }

  return (
    <VideoAnalyticsComponents.ProjectRoleController projectId={projectId || ""}>
      <SharedComponents.Screen>
        <SharedComponents.Modal
          type={SharedTypes.ModalTypes.DEFAULT}
          confirmText={"EDIT THE PROJECT"}
          onConfirm={() => setAssistanceModalOpen(false)}
          title={
            "Do you need assistance editing this project (i.e. panel, scheduling, etc.)?"
          }
          hasConciergeNote={true}
          isOpen={isAssistanceModalOpen}
        />
        <SharedComponents.FormikLib.Form
          validationSchema={validationSchema}
          initialValues={formValues}
          onSubmit={(values: IValues) => alert(JSON.stringify(values))}
        >
          <Pages.AddProjectTemplate
            steps={PROJECT_EDITION_STEPS}
            title={"Edit Project"}
            paddingRight={64}
          >
            <LocalPages.ProjectDetailsPage projectId={projectId || ""} />
            <Templates.ProjectTagsPage />
            <LocalPages.UploadVideos />
            <LocalPages.CostsEstimatePage isEditMode={false} />
          </Pages.AddProjectTemplate>
        </SharedComponents.FormikLib.Form>
      </SharedComponents.Screen>
    </VideoAnalyticsComponents.ProjectRoleController>
  );
});
