import * as Yup from "yup";

import { Types } from "@videoAnalytics";

export const validationSchema = Yup.object().shape({
  id: Yup.string(),
  name: Yup.string()
    .min(2, "At least 2 characters")
    .max(60, "Not more than 60 characters")
    .required("This field is required"),
  summary: Yup.string().required("This field is required"),
  email: Yup.string().email("Invalid email"),
  costCenter: Yup.string().required("This field is required"),
  consentFormLink: Yup.string().required("This field is required"),
  cmk: Yup.string().required("This field is required"),
  members: Yup.array(),
  customTag: Yup.string(),
  tags: Yup.array(),
  brands: Yup.array(),
  regions: Yup.array().min(1, "You need to select at least one region"),
  // .test({
  //   name: "regions",
  //   message:
  //     "While you selected Grt. China, videos may not be uploaded at this time",
  //   test: (value) => {
  //     return (
  //       value !== undefined &&
  //       !value.includes("70b5aeec-163d-4be0-be6e-f8164cfcc002")
  //     );
  //   },
  // }),
  countries: Yup.array().min(1, "You need to select at least one country"),
  // .test({
  //   name: "regions",
  //   message:
  //     "While you selected a country from Grt. China region, videos may not be uploaded at this time",
  //   test: (value) => {
  //     return (
  //       value !== undefined &&
  //       !value.includes("c8d83207-4c88-411a-8958-bbcfb5a490e9") &&
  //       !value.includes("dea67dcf-8767-4a81-b92e-e4e43ec4340a") &&
  //       !value.includes("23b66f94-24b4-42e8-927f-92a972b3a329") &&
  //       !value.includes("9a5522ed-61c3-472e-99f6-2c9dc74a8109")
  //     );
  //   },
  // }),
  categories: Yup.array().min(1, "You need to select at least one category"),
  videos: Yup.array(),
  startDate: Yup.date().required("This field is required"),
  endDate: Yup.date().required("This field is required"),
  FSDSUploadDate: Yup.date(),
  hasPii: Yup.boolean().required("This field is required"),
  qualityMode: Yup.string().required("You need to select the processing quality"),
  blurMode: Yup.string().when("hasPii", {
    is: true,
    then: Yup.string().required("You need to select an algorithm"),
    otherwise: Yup.string().notRequired(),
  }),
  hasSignedInformedConsent: Yup.boolean()
    .required("This field is required")
    .oneOf(
      [true],
      "Videos may not be uploaded to the VAP without informed consent"
    ),
  hasVideoPersonsUnder18: Yup.boolean().required("This field is required"),
  hasConsentFormSignedByParent: Yup.boolean().when("hasVideoPersonsUnder18", {
    is: true,
    then: Yup.boolean().oneOf(
      [true],
      "Videos may not be uploaded to the VAP without informed consent"
    ),
    otherwise: Yup.bool().notRequired(),
  }),
  files: Yup.array(),
  hasDelayedFSDSUpload: Yup.boolean(),
  hasCostsAgreement: Yup.boolean()
    .required("You should agree to the total cost estimate")
    .oneOf([true], "You should agree to the total cost estimate"),
});

export const initialValues: Types.ICreateProjectFormValues = {
  id: "",
  hasPii: undefined,
  hasSignedInformedConsent: undefined,
  hasConsentFormSignedByParent: true,
  hasVideoPersonsUnder18: undefined,
  hasDelayedFSDSUpload: false,
  hasCostsAgreement: false,
  status: undefined,
  members: [],
  tags: [],
  brands: [],
  regions: [],
  countries: [],
  categories: [],
  videos: [],
  blurMode: "",
  qualityMode: "",
  customTag: "",
  name: "",
  summary: "",
  email: "",
  costCenter: "",
  consentFormLink: "",
  cmk: "",
  startDate: new Date(),
  endDate: new Date(),
  FSDSUploadDate: new Date(),
  files: [],
};

export const filledValues: Types.ICreateProjectFormValues = {
  id: "",
  hasPii: false,
  hasSignedInformedConsent: undefined,
  hasConsentFormSignedByParent: true,
  hasVideoPersonsUnder18: undefined,
  hasDelayedFSDSUpload: false,
  hasCostsAgreement: false,
  status: undefined,
  members: [],
  tags: [],
  brands: [],
  regions: [],
  countries: [],
  categories: [],
  videos: [],
  blurMode: "",
  qualityMode: "",
  customTag: "",
  name: "May 2 001",
  summary: "summary",
  email: "",
  costCenter: "",
  consentFormLink: "",
  cmk: "0",
  startDate: new Date(),
  endDate: new Date(),
  FSDSUploadDate: new Date(),
  files: [],
};
