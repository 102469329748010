import { useField, useFormikContext } from "formik";
import { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { computed } from "mobx";

import { SharedComponents, Theme, Pages } from "@shared";
import { Types, VideoAnalyticsStore } from "@videoAnalytics";

import * as AddProjectComponents from "../../../video_analytics/pages/add_project/components";
import * as ProjectAlgoComponents from "./components";

export const ProjectAlgorithmsPage = observer(() => {
  const {
    spacing: { sm, md, lg },
    palette: { primaryBlue },
  } = Theme.useStyledTheme();

  const {
    createProject,
    data: { successes, loadings },
  } = VideoAnalyticsStore;
  const createProjectQueryHasSuccess: boolean = computed(
    () => successes.get(Types.Endpoints.CREATE_PROJECT) || false
  ).get();
  const createProjectQueryHasLoading: boolean = computed(
    () => loadings.get(Types.Endpoints.CREATE_PROJECT) || false
  ).get();

  const [, , helpers] = useField<string>("id");
  const { values } = useFormikContext<Types.ICreateProjectFormValues>();
  const { currentStep, setCurrentStep } = SharedComponents.useStepperContext();

  const onNextHandler = useCallback(async () => {
    await createProject(values, (id) => helpers.setValue(id));
  }, [createProject, values]);

  const onBackHandler = useCallback(
    () => setCurrentStep(currentStep - 1),
    [setCurrentStep, currentStep]
  );

  // const onReload = useCallback(
  //   () => createProject(values, (id) => helpers.setValue(id)),
  //   [createProject, values]
  // );
  useEffect(() => {
    if (createProjectQueryHasSuccess) {
      setCurrentStep(currentStep + 1);
    }
  }, [createProjectQueryHasSuccess, setCurrentStep, currentStep]);

  return (
    <Pages.AddProjectStepTemplate
      fields={values.hasPii ? ["blurMode", "qualityMode"] : ["qualityMode"]}
      nextTitle="Next"
      onPrev={onBackHandler}
      onNext={onNextHandler}
    >
      <SharedComponents.Box>
        <AddProjectComponents.LoadingModal
          title={"The project is being created"}
          isOpen={createProjectQueryHasLoading}
        />
        <SharedComponents.VerticalBox height={44} />
        <ProjectAlgoComponents.BlurTypes field={"blurMode"} />
        <ProjectAlgoComponents.QualityTypes field={"qualityMode"} />
        <SharedComponents.Text type="h3SemiBold" text="Video Analytics Cost:" />
        <SharedComponents.VerticalBox height={sm} />
        <SharedComponents.Row>
          <SharedComponents.Icons.IconCost />
          <SharedComponents.HorizontalBox width={sm} />
          <SharedComponents.Text
            text="$1.50/minute"
            type="h3SemiBold"
            color={primaryBlue}
          />
          <SharedComponents.Text
            text=" (rounded to the whole minute)"
            type="h3"
            color={primaryBlue}
          />
        </SharedComponents.Row>
        <SharedComponents.VerticalBox height={sm} />
        <ProjectAlgoComponents.ULAnalytics />
        <SharedComponents.VerticalBox height={lg} />
        <SharedComponents.Text
          type="h3SemiBold"
          text="Optional Costs:"
          margin={`${md}px 0`}
        />
        <SharedComponents.FlexContainer padding={0} justifyContent="flex-start">
          <SharedComponents.Icons.IconCost />
          <SharedComponents.Text
            margin={`0 ${sm}px`}
            text="$40/hour"
            type="h3SemiBold"
            color={primaryBlue}
          />
        </SharedComponents.FlexContainer>
        <SharedComponents.VerticalBox height={sm} />
        <ProjectAlgoComponents.UlOptionalCosts />
      </SharedComponents.Box>
    </Pages.AddProjectStepTemplate>
  );
});
