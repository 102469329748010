import { SharedComponents, Theme } from "@shared";

import { IQualityTypeItem } from "../types";
import { useField } from "formik";

export const QualityTypeItem = ({ src, name, field, description, spec, value }: IQualityTypeItem) => {
  const {
    spacing: { sm },
    palette: { primaryBlue },
  } = Theme.useStyledTheme();

  const [input, , helpers] = useField(field);
  return (
    <SharedComponents.Column>
      <video height={"244px"}>
        <source src={src} type="video/mp4"/>
      </video>
      <SharedComponents.Row alignItems={"center"}>
        <input
          type={"radio"}
          checked={input.value === value}
          onChange={() => helpers.setValue(value)}
        />
        <SharedComponents.HorizontalBox width={sm} />
        <SharedComponents.Text
          type={"button"}
          text={name}
          color={primaryBlue}
        />
      </SharedComponents.Row>
      <SharedComponents.Row alignItems={"center"}>
        <SharedComponents.Text type={"small"} text={spec} />
      </SharedComponents.Row>
      <SharedComponents.Row alignItems={"center"}>
        <SharedComponents.Text type={"small"} text={description} />
      </SharedComponents.Row>
    </SharedComponents.Column>
  );
};
