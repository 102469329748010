import {computed} from "mobx";
import {observer} from "mobx-react";
import {useNavigate} from "react-router-dom";
import {useField} from "formik";
import {FC, useCallback, useEffect, useState} from "react";

import {
    SharedComponents,
    Types as SharedTypes,
    SharedStore,
    Theme,
} from "@shared";
import {CommonPages} from "@modules";
import {VideoAnalyticsStore, Types} from "@videoAnalytics";

import * as LocalComponents from "./components";

interface IVideoProjectsPage {
}

const ProjectType = {
    MY: 'MY',
    SHARED: 'SHARED',
    ALL: 'ALL'
};

export const VideoProjectsPage: FC<IVideoProjectsPage> = observer(() => {
    /**
     * theme data
     * */
    const {
        spacing: {md},
    } = Theme.useStyledTheme();

    const [searchModalOpened, setSearchModalOpened] = useState(false);
    /**
     * formik data
     * */
    const [input, , helpers] = useField<string>("projectsFilter");
    /**
     * navigation helpers
     * */
    const navigate = useNavigate();
    /**
     * storage values
     * */
    const {
        data: {hasNonEmpRole, hasAdminRole, hasEBPRole, hasNoneRole},
        // data: { hasAdminRole, hasNonEmpRole, hasEBPRole, hasNoneRole },
    } = SharedStore;
    const {
        data: {
            projects,
            myProjectsStats,
            projectsStats,
            sharedProjectsStats,
            hasMoreProjects,
            setCurrentProjectsPage,
            setMoreProjects,
            loadings,
        },
        getMoreProjects,
        getMoreUserProjects,
        getMoreSharedProjects,
        getProjects,
        getProjectsStats,
        getMyProjects,
        getMyProjectsStats,
        getSharedProjects,
        getSharedProjectsStats,
    } = VideoAnalyticsStore;

    const projectsQueryHasLoading = computed(
        () =>
            loadings.get(Types.Endpoints.PROJECTS) ||
            loadings.get(Types.Endpoints.USER_PROJECTS)
    ).get();
    const projectsStatsQueryHasLoading = computed(() =>
        loadings.get(Types.Endpoints.PROJECTS_STATS)
    ).get();
    const deleteProjectMutationHasLoading = computed(() =>
        loadings.get(Types.Endpoints.DELETE_PROJECT)
    ).get();

    const listData = computed(() => projects).get();
    const statsData = computed(() =>
        () => {
            let stats: Types.IProjectsStats | undefined;
            switch (input.value) {
                case "MY":
                    stats = myProjectsStats;
                    break;
                case "SHARED":
                    stats = sharedProjectsStats;
                    break;
                case "ALL":
                    stats = projectsStats;
                    break;
                default:
                    stats = sharedProjectsStats;
                    break;
            }
            if (!stats) {
                return {
                    projectsNumber: 0,
                    membersNumber: 0,
                    videosNumber: 0,
                    totalLength: "0"
                };
            }
            return stats;
        }
    ).get();

    const next = function () {
        switch (input.value) {
            case "MY":
                return getMoreUserProjects;
            case "SHARED":
                return getMoreSharedProjects;
            case "ALL":
                return getMoreProjects;
            default:
                return getMoreSharedProjects;
        }
    }

    const [projectType, setProjectType] = useState(ProjectType.MY);

    const handleProjectTypeChange = useCallback((newType: string) => {
        if (hasEBPRole) return;

        setProjectType(newType);
        // If you need to update the form field
        helpers.setValue(newType);
    }, [helpers, hasEBPRole]);

    /**
     * side effects
     * */
    useEffect(() => {
        switch (input.value) {
            case "MY":
                console.log("MY");
                getMyProjects();
                getMyProjectsStats();
                break;
            case "SHARED":
                console.log("SHARED");
                getSharedProjects();
                getSharedProjectsStats();
                break;
            case "ALL":
                console.log("ALL");
                getProjects();
                getProjectsStats();
                break;
            default:
                console.log("SHARED");
                getSharedProjects();
                getSharedProjectsStats();
                break;
        }

        return () => {
            setCurrentProjectsPage(1);
            setMoreProjects(true);
        };
    }, [input.value]);

    /**
     * ui if statements
     * */
    if (
        projectsQueryHasLoading ||
        projectsStatsQueryHasLoading ||
        deleteProjectMutationHasLoading
    ) {
        return <SharedComponents.LoadingIndicator/>;
    }

    if (hasNoneRole) {
        return <SharedComponents.DeniedIndicator/>;
    }

    return (
        <SharedComponents.Box width={"100%"}>
            <LocalComponents.SearchModal
                isOpened={searchModalOpened}
                onClose={() => setSearchModalOpened(false)}
            />
            <SharedComponents.VerticalBox height={32}/>
            <SharedComponents.Row
                flex={0}
                height={"fit-content"}
                justifyContent={"flex-end"}
            >
                {!hasEBPRole && !hasNoneRole && (
                    <SharedComponents.Row>
                        <SharedComponents.FormikLib.Radio
                            subtitle="My projects"
                            field="showMyProject"
                            onClick={() => handleProjectTypeChange(ProjectType.MY)}
                            value={projectType === ProjectType.MY}
                        />
                        <SharedComponents.HorizontalBox width={md}/>
                        <SharedComponents.FormikLib.Radio
                            subtitle="Shared projects"
                            field="showSharedProject"
                            onClick={() => handleProjectTypeChange(ProjectType.SHARED)}
                            value={projectType === ProjectType.SHARED}
                        />
                        {hasAdminRole && (
                            <>
                                <SharedComponents.HorizontalBox width={md}/>
                                <SharedComponents.FormikLib.Radio
                                    subtitle="All projects"
                                    field="showAllProject"
                                    onClick={() => handleProjectTypeChange(ProjectType.ALL)}
                                    value={projectType === ProjectType.ALL}
                                />
                            </>
                        )}
                    </SharedComponents.Row>
                )}

                {!hasEBPRole && !hasNonEmpRole && !hasNoneRole && (
                    <SharedComponents.Button
                        type={SharedTypes.ButtonType.FILLED}
                        text={"ADD PROJECT"}
                        onClick={() =>
                            navigate(SharedTypes.PATHS.VIDEO_ANALYTICS_PROJECT_ADD)
                        }
                    />
                )}
            </SharedComponents.Row>
            <SharedComponents.VerticalBox height={16}/>
            <LocalComponents.SearchBar onClick={() => setSearchModalOpened(true)}/>
            {/*<SharedComponents.AdvancedFilters*/}
            {/*  searchField="text"*/}
            {/*  data={advancedFiltersData}*/}
            {/*  isElastic*/}
            {/*/>*/}
            <SharedComponents.VerticalBox height={16}/>
            {listData.length === 0 && <CommonPages.Empty/>}
            {listData.length > 0 && (
                <>
                    <LocalComponents.Summary data={statsData()}/>
                    <SharedComponents.InfiniteScrollList
                        data={projects}
                        hasMore={hasMoreProjects}
                        next={next()}
                        ListItem={LocalComponents.ListItem}
                    />
                </>
            )}
        </SharedComponents.Box>
    );
});
