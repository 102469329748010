import { IQualityTypeItem } from "../types";

enum QualityOptions {
  Low = "Low",
  Medium = "Medium",
  High = "High",
}

export const OPTIONS: Array<IQualityTypeItem> = [
  {
    src: "https://d3imuxqtg7nwqt.cloudfront.net/input/78aa0eca-0453-4685-834f-f3bb9ba2ceae/35e731c8-8898-4f85-b815-cd8c02e9c9b3/03f1c6e3-da2a-4ea4-ade7-7798442251d2/03f1c6e3-da2a-4ea4-ade7-7798442251d2.mp4?Expires=4886058150&Signature=mwi2aSXS7ulKfRP94acRlbrLh44AacgRqePFaOMm1Gz6XL5A1BfPK4S18sKNj-PeKp8~eTDaGD7a9lFE5duGNunarsEnQ11JgVBJi4G~Ec-hksOTng-Fv3MRWhHg2xqPD2mbNOQU2gDb-9ujwn2U-KM7EZ~lrpQImFo5JcLBVRgfHNzGNVBrcuZSPHvw6Lf~qt8ZlUtLcoQ0aXcS8YNNKICY3BjjoQBiL00hvOpa8MdrKgt-gUjhBXarRx3cEVTLpyoEBQIiUoqMDyFNhO8A~PG7t2CJAM5ApSJbwptrUBT6iygNzxTZpWcPsUJ6G2RA9jIFoJxtUJY2jvCGkj-9kQ__&Key-Pair-Id=K29BKVDY3IHNBF",
    name: "Low quality",
    description: "Choose this option if you want to save storage space and bandwidth.",
    spec: "8 fps 720p",
    field: "QualityMode",
    value: QualityOptions.Low,
  },
  {
    src: "https://d3imuxqtg7nwqt.cloudfront.net/input/78aa0eca-0453-4685-834f-f3bb9ba2ceae/ed209cce-63ef-4f94-8e62-74f154fda9bd/b6840e6b-5dc8-4bd9-bb95-ec9385adae8e/b6840e6b-5dc8-4bd9-bb95-ec9385adae8e.mp4?Expires=4886057999&Signature=SGo7~nqcCsZ3cz9tqjN0AXTW8rAH1hPRObEg9ApaLQTRjB7qDPxWsWQ3fmOmo1dCtAAG35Mar0B1LXfxJSX6fdkuSU6nSk2lOZ4LYay0mbiURaloi8-ouIu~7l0Spmya~TR~nR6f4zpkT8fFi6hwbTxYXJug8d8ZNe9uYAlT0RyBigz5Zy~ML9RYlnpw6tQSdC8On6hFjnkpqfUh2FDQVdGqf2ktERDWr3kRD1bel4Y2U4GTKtqyVM5rDsxERr7ItpslD-G4jJotNGVS0UQkzJIvXb610vJSD4tv2jcSOWaBZqkwqHdIVJ2DNsCokJxVT61aibW8EgPZaCZZIEYZpw__&Key-Pair-Id=K29BKVDY3IHNBF",
    name: "Medium quality",
    description: "Choose this option if you want to balance between quality and storage space.",
    field: "QualityMode",
    spec: "24 fps 1080p",
    value: QualityOptions.Medium,
  },
  {
    src: "https://d3imuxqtg7nwqt.cloudfront.net/input/78aa0eca-0453-4685-834f-f3bb9ba2ceae/a301976e-41a1-473c-a743-5426a66c9d19/f53d7174-fa51-4696-9beb-db391302cd7d/f53d7174-fa51-4696-9beb-db391302cd7d.mp4?Expires=4886058214&Signature=g96t3aKM5MCnTKcHemO~8tjJnjLVaLyqZCd1DVHcrpS2RB2JCrKI~Zc-Q8K7JbGAIhye3DVK93wK~Tovr7l-fC-AvLpi6xypMj8jXZ5hUZQX5ZsA3sLVtzw9AdIRAYwB3EF6c4OoTp404-70OhmGClbONWg0FMit472HqKmGBUR4tqmgZruo1hS7VbnGhU3GC4VEj6Yu97GSn7MXiy8-GA06Hk3tzVjmf2Vpmc5ohjBGTL4rslcoz~PnvvHEUn2-SLvPYnPl5GwDMet29cfXM6aC6r6xHkHVoCsYqrnmalGIJ6m4kG0fVysh90vjWep2rEg0f8beZVRitzZ3hJ1oMQ__&Key-Pair-Id=K29BKVDY3IHNBF",
    name: "High quality",
    description: "Choose this option if you want to have the highest quality possible.",
    field: "QualityMode",
    spec: "30 fps 1080p",
    value: QualityOptions.High,
  },
];
